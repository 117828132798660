.phone-input{
    width:98%!important;
    height:44px!important;
    border-color: #E5E6E8!important;
    color:#13161D!important;
    font-size: 12px!important;
    margin-left: 10px!important;
    font-family: 'Inter'!important;
}


iframe[title= 'reCAPTCHA']{
    width: 100%!important;
}

.rc-anchor-light {
    background-color: white!important;
}

.rdt_TableCol_Sortable > span {
    display: none;
}